import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="Red Rocket Deluxe"
      heroSubTitle="Hard Rock and Party Metal"
      heroBackgroundImage="/images/red-rocket-deluxe.jpg"
    >
      <SEO
        title="Red Rocket Deluxe | Hard Rock | Party Metal Atlanta, Georgia"
        ogDescription="Red Rocket Deluxe have been making indie pop in Atlanta, GA on and off since 1991. Described as “The Pixies meet The Partridge Family."
        image="https://redrocketdeluxe.com/images/red-rocket-deluxe-live.jpg"
        url="https://redrocketdeluxe.com"
      />
      <TextBlock
        textPadded
        textLeft={
          <div id="about">
            <p>
              RED ROCKET DELUXE are an Atlanta-based hard rock/heavy metal band. They combine the posture of 80's metal, the jest of party rock and the sonic impact of a sludge band. The band was started by Randy King, Brandon Forsyth, Jon Wheeler and Marco Gollarza (RIP). They invested heavily in their songwriting and performance while lovingly poking fun at the absurdities of the genre. Basically, they are KISS meets Kyuss.
            </p>
            <p>
              Their final full-length album is entitled "High Atop The Mount" was released on transparent red 12" vinyl and high resolution digital download by the <a href="https://headphonetreats.com" target="_blank">Headphone Treats</a> imprint.
            </p>
            <p>
              Reissues of their first two albums are in the works.
            </p>
            <ScrollToButton
              id="final-live-show-video"
              content="Final Live Show Video"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
        textRight={
          <div>
            <img src="/images/red-rocket-deluxe-live.jpg" alt="Red Rocket Deluxe Party Metal Atlanta" />
          </div>
        }
      />
      <PageWrapper color="charcole">
        <SingleColumnBlock
          centered
          text={
            <div>
              <img src="/images/red-rocket-deluxe-color-logo.png" alt="Red Rocket Deluxe Party Metal Atlanta" />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>"High Atop The Mount" LP out now</h3>
              <p>
                The record weaves an array of influences and sub-genres of heavy rock into two solid album sides. The punk-tinged NWOBHM-style songs "Doom and Gloom" and "Black Dawn" shift effortlessly into sludgy breakdowns. Power-metal stingers "Tampa Bound", "Stuck" and "Stalker" tug at the day-to-day realities of doomed relationships and endless responsibilities, but RED ROCKET DELUXE never takes itself too seriously for long. They lovingly deliver a lyrical send-up of every conceivable Ronnie James Dio trope in "DinoRider" and throw around downright anthemic absurdity on "Mother of Pearl" and "Space Dolphins (In Space)". The album wraps up with "Mistress" and "Rock and Roll" -- both celebrating the comfort and direction found by paying music.
              </p>
              <p>
                "High Atop The Mount" is available on transparent red 12" vinyl at <a href="https://redrocketdeluxe.bandcamp.com/album/high-atop-the-mount" target="_blank">Bandcamp</a>.
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy format of your choice at <a href="https://redrocketdeluxe.bandcamp.com/album/high-atop-the-mount" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Coming soon!
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/high-atop-the-mount-red-rocket-deluxe.jpg" alt="High Atop the Mount LP by Red Rocket Deluxe" />
            </div>
          }
        />
      </PageWrapper>
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/randy-king-red-rocket-deluxe.jpg" alt="Randy King of Red Rocket Deluxe" />
            </div>
          }
          textRight={
            <div>
          <h3>Randy King</h3>
          <h4>Guitar/Vocals</h4>
          <p>
            When he's not showboating on stage, Randy King is a <a href="https://kingelectricguitars.com" target="_blank">custom guitar builder</a>.
          </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>Jon Wheeler</h3>
              <h4>Bass</h4>
              <p>
                Jon brings the thunder.
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/jon-wheeler-red-rocket-deluxe.jpg" alt="Jon Wheeler of Red Rocket Deluxe" />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/brandon-forsyth-red-rocket-deluxe.jpg" alt="Brandon Forsyth of Red Rocket Deluxe" />
            </div>
          }
          textRight={
            <div>
          <h3>Brandon Forsyth</h3>
          <h4>Guitar/Vocals</h4>
          <p>
            Brandon brings the Iron Madien.
          </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>Marco Gollarza</h3>
              <h4>Drums</h4>
              <p>
                 Marco was the literal heartbeat of the band. He passed away in August of 2017.
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/marco-gollarza-red-rocket-deluxe.jpg" alt="Marco Gollarza of Red Rocket Deluxe" />
            </div>
          }
        />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/red-rocket-deluxe-party-metal.jpg" alt="Red Rocket Deluxe Live Events" />
          </div>
        }
        textRight={
          <div>
            <h2>Keep in touch!</h2>
            <p>
              Follow us on <a href="https://www.facebook.com/RedRocketDeluxe" target="_blank">Facebook</a>. You can also follow Randy on <a href="https://tiktok.com/@kingelectricguitars" target="_blank">TikTok</a>.
            </p>
          </div>
        }
      />
      <PageWrapper color="charcole">
        <div id="final-live-show-video">
            <VideoPlayer youtubeId='j5tVoX-wQfs' />
        </div>
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Contact Red Rocket Deluxe</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
